<template>
  <div class="quick-view__left">
    <div
      v-if="propData.collectImg"
      class="quick-view__left-main"
    >
      <div class="img-wrapper">
        <img
          :src="propData.collectImg"
          @click="handleMainImgClick"
        />
      </div>
    </div>
    <div
      v-if="propData.length && propData[0].series_img"
      ref="theme-image-swiperv2"
      class="swiper-container theme-image-swiperv2"
    >
      <div
        v-show="activeIndex !== 0"
        ref="theme-image-swiperv2__prev"
        class="quick-view__left-swiper__prev theme-image-swiperv2__prev"
        @click="handleArrowBtn('left')"
      >
        <i class="svgicon svgicon-arrownew-right"></i>
      </div>
      <swiper-container 
        ref="swiper"
        init="false"
        class="theme-image-swiper-wrapper s-swiper-wrapper"
      >
        <swiper-slide
          v-for="(themeItem, themeIndex) in propData"
          :key="`themeItem_${themeIndex}`"
          class="s-swiper-slide"
        >
          <div
            class="quick-view__left-main theme-image__left-main"
          >
            <div class="img-wrapper">
              <img
                :src="themeItem.series_img"
                @click="handleMainImgClick"
              />
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
      <div
        v-show="activeIndex !== (propData.length - 1)"  
        ref="theme-image-swiperv2__next"
        class="quick-view__left-swiper__next theme-image-swiperv2__next"
        @click="handleArrowBtn('right')"
      >
        <i class="svgicon svgicon-arrownew-left"></i>
      </div>
    </div>
    <div
      v-if="products.length"
      ref="quick-view__left-list-ref"
      class="quick-view__left-list j-expose__left-list"
      :class="{
        'not-series-img': !isShowSeries,
      }"
    >
      <div
        v-for="(item, index) in products"
        :key="`quick-left-product__item-${index}`"
        class="j-expose__left-item"
        :data-expose-id="`quick-left-product__item-${index}`"
      >
        <div
          :ref="item.goods_id == activeId ? 'quick-view__left-active-ref' : ''"
          v-expose="getAnlysis('2-3-13', { item, index })"
          class="quick-view__left-list-item j-expose__left-con"
          :class="{ active: item.goods_id == activeId }"
          :data-goods_id="item.goods_id"
          :data-spu="item.productRelationID || item.goods_sn"
          :data-us-price="item.salePrice && item.salePrice.usdAmount"
          :data-us-origin-price="item.retailPrice && item.retailPrice.usdAmount"
          :data-goods-id="item.goods_id"
          :data-cat_id="item.cat_id"
          :data-sku="item.goods_sn"
          :data-index="index"
          :data-price="item.salePrice && item.salePrice.amount"
          :data-mall_tag_code="`${getMallTag(item.mall_code)}_${
            item.mall_code || ''
          }`"
          :data-theme-name="propData.theme_name || ''"
          :data-theme-id="propData.id || ''"
          :data-soldout_st="isSoldout(item)"
          @click="itemClickHandle($event.currentTarget)"
        >
          <div class="item-left">
            <img
              :src="item.goods_thumb || item.goods_img"
            />
            <div
              v-if="item.is_on_sale == 0 || item.stock == 0"
              class="sold-out"
            >
              {{ language.SHEIN_KEY_PC_14617 }}
            </div>
          </div>
          <div class="item-right">
            <p class="item-right__title">
              {{ item.cat_name }}
            </p>
            <h4
              class="item-right__price"
              :class="{ sale: discountCalc(item) && !isHitComplianceMode }"
            >
              <template
                v-if="
                  quickViewConfig.showNewFlashPriceOnSale &&
                    item.newFlashPromotion
                "
              >
                {{
                  item.newFlashPromotion.price &&
                    item.newFlashPromotion.price.amountWithSymbol
                }}
              </template>
              <template v-else-if="item?.flashZonePromotion?.price">
                {{ item.flashZonePromotion.price?.amountWithSymbol }}
              </template>
              <template v-else>
                {{ item.salePrice && item.salePrice.amountWithSymbol }}
              </template>
              <del v-if="discountCalc(item) && !isHitComplianceMode && item.retailPrice">{{
                item.retailPrice.amountWithSymbol
              }}</del>
            </h4>
            <template v-if="quickViewConfig.showSheinClubDiscountValue">
              <ProductItemSheinClubDiscount
                class="item-right__club"
                type="small"
                :discount-value="calClubDisCountValue(item)"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 查看大图 -->
    <client-only>
      <QuickViewImageModal
        :images-show="imagesModalShow"
        :images-arr="zoomOutImg"
        :active-index="collocationIndex"
        :goods-name="goodsName"
        :atom-flow-config="atomFlowConfig"
        @change-index="changeActiveIndex"
        @close="imagesModalShow = false"
      />
    </client-only>
  </div>
</template>

<script>
import { transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
import goodsExpose from 'public/src/services/goodsExpose'
import { ClientOnly } from '@sheinfe/vue-client-only'
import QuickViewImageModal from './QuickViewImageModal.vue'
import ProductItemSheinClubDiscount from 'public/src/pages/components/productItem/components/ProductItemSheinClubDiscount.vue'
const { PUBLIC_CDN, IS_RW } = gbCommonInfo
daEventCenter.addSubscriber({ modulecode: '2-3' })
import { register } from 'swiper/element'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()

function animateScroll(element, targetScrollTop, duration) {
  const startScrollTop = element.scrollTop
  const change = targetScrollTop - startScrollTop
  const startTime = performance.now()

  function animate(time) {
    const elapsed = time - startTime
    const progress = Math.min(elapsed / duration, 1) // 确保 progress 不超过 1
    const newScrollTop = startScrollTop + change * progress
    element.scrollTop = newScrollTop

    if (progress < 1) {
      requestAnimationFrame(animate)
    }
  }

  requestAnimationFrame(animate)
}
export default {
  name: 'QuickViewLeftProducts',
  components: { 
    ClientOnly,
    QuickViewImageModal,
    ProductItemSheinClubDiscount 
  },
  props: {
    goodsData: {
      type: Object,
      default() {
        return {}
      },
    },
    propData: {
      type: [Object, Array],
      default() {
        return {}
      },
    },
    activeId: {
      type: [String, Number],
      default() {
        return ''
      },
    },
    analysis: {
      type: Object,
      default() {
        return {}
      },
    },
    quickViewCb: {
      type: Object,
      default() {
        return {}
      },
    },
    rootStatus: {
      type: Object,
      default() {
        return {}
      },
    },
    siteMallInfo: {
      type: Array,
      default() {
        return []
      },
    },
    language: {
      type: Object,
      default() {
        return {}
      },
    },
    isHitComplianceMode: {
      type: Boolean,
      default: false,
    },
    quickViewConfig: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  inject: ['changeRootStatus', 'changeRootData'],
  data() {
    return {
      PUBLIC_CDN,
      IS_RW,
      collocationIndex: 0,
      imagesModalShow: false,
      themeImageSwiper: null,
      activeIndex: 0
    }
  },
  computed: {
    products() {
      return (this.propData && this.propData.product) || (this.propData.length && this.propData[this.collocationIndex].goods) || []
    },
    themeId() {
      return this.propData?.themeId || (this.propData.length && this.propData[this.collocationIndex].theme_id) || ''
    },
    isSquare() {
      return this.propData.length ? !!!+this.propData[this.collocationIndex]?.image_type : !!!+this.propData?.image_type
    },
    zoomOutImg() {
      return this.propData && this.propData.length && this.propData.map((item) => {
        item.origin_image = item.series_img
        item.thumbnail = item.series_img
        return item
      })
    },
    isShowSeries() {
      const { propData } = this
      return (propData.length && propData[0].series_img) || propData.collectImg
    },
  },
  watch: {
    products: {
      handler(data) {
        if (!data.length) return
        this.switchToShow()
      },
      deep: true,
    },
    propData: {
      handler(newData, oldData) {
        if (newData.length !== oldData) {
          this.collocationIndex = 0
        }
        if (!this.themeImageSwiper) {
          Vue.nextTick(() => {
            const swiperEl = this.$refs.swiper
            if (!swiperEl) return
            const swiperParams = {
              observer: true,
              observeParents: true,
              slidesPerGroup: 1,
              loop: true,
              preventClicksPropagation: true,
              on: {
                slideChangeTransitionStart: (swiper) => {
                  this.activeIndex = swiper.realIndex
                },
                slideChangeTransitionEnd: (swiper) => {
                  this.collocationIndex = swiper.realIndex //切换结束时，告诉我现在是第几个slide
                  this.products = this.propData[this.collocationIndex].goods
                  this.$emit('onSlideChangeEnd', this.products[0].goods_id)
                },
                click: (swiper, event) => {       
                  const targetClassName = event.target.className
                  if (!targetClassName.includes('svgicon')) {
                    this.imagesModalShow = true
                    this.collocationIndex = swiper.realIndex
                  }
                },
              }
            }
            Object.assign(swiperEl, swiperParams)
            swiperEl.initialize()
            this.themeImageSwiper = swiperEl.swiper
          })
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.switchToShow()
  },
  methods: {
    /**
      * 点击左右箭头
    */
    handleArrowBtn(direction) {
      if (direction === 'left') {
        this.themeImageSwiper.slidePrev()
      } else if (direction === 'right') {
        this.themeImageSwiper.slideNext()
      }
    },
    handleMainImgClick() {},
    changeActiveIndex({ index }) {
      this.collocationIndex = index
    },
    isSoldout(item) {
      return 'soldout_' + (Number(item.is_on_sale == 1 && item.stock > 0) || 0)
    },
    calClubDisCountValue(item) {
      return item?.sheinClubPromotionInfo?.discountValue || 0
    },
    itemClickHandle(target) {
      const goods_id = target.getAttribute('data-goods_id')
      const index = Number(target.getAttribute('data-index'))
      if (goods_id) {
        if (this.activeId == goods_id) return
        this.$emit('handleItemClick', {
          goods_id,
          goodsIndex: index
        })
        daEventCenter.triggerNotice({
          daId: '2-3-14',
          target,
          extraData: {
            activity_from: this.analysis?.sa?.activity_from || '',
            sets_list: `${index + 1}\`${this.themeId}`,
            style: this.analysis?.sa?.style || '',
            abtest: this.analysis?.sa?.abtest || '',
          },
        })
        this.switchToShow()
      }
    },
    switchToShow() {
      this.$nextTick(() => {
        requestAnimationFrame(() => {
          const { isSquare } = this
          const LIST_DOM = this.$refs['quick-view__left-list-ref']
          const ACTIVE_DOM = this.$refs['quick-view__left-active-ref']?.[0]
          const targetScrollTop = ACTIVE_DOM.offsetTop - (isSquare ? 30 : 15)
          animateScroll(LIST_DOM, targetScrollTop, 300)
        })
      })
    },
    getMallTag(mall_code) {
      return (
        this.siteMallInfo.find((_) => _.mall_code == mall_code)?.mall_tags || ''
      )
    },
    discountCalc(item) {
      return (
        item.unit_discount > 0 ||
        (item.salePrice &&
          item.retailPrice &&
          item.salePrice.amount > item.retailPrice.amount) ||
        item.newFlashPromotion || item?.flashZonePromotion
      )
    },
    getAnlysis(id) {
      return {
        id,
        code: 'QuickViewLeftProducts',
        data: {
          activity_from: this.analysis?.sa?.activity_from || '',
          scene_list: this.analysis?.sa?.scene_list || '',
          style: this.analysis?.sa?.style || '',
          abtest: this.analysis?.sa?.abtest || '',
        },
      }
    },
    exposeProductItem() {
      const expose = new goodsExpose()
      expose.initInstance({
        config: {
          targetExposeRadio: 0.3,
          scrollContainer: '.quick-view__left-list',
          section: [
            {
              code: 'quickViewLeftListContent', // 唯一标识
              container: 'left-list', // 一级包含块
              content: 'left-item', // 二级包含块
              target: 'left-con', // 目标元素
              averageCotent: false,
            },
          ],
        },
        getExposeData: ({ list }) => {
          Object.keys(list).forEach((type) => {
            let products = []
            let sets_list = []
            list[type].forEach(({ target }) => {
              products.push(target)
              const index = Number(target.getAttribute('data-index'))
              sets_list.push(`${index + 1}\`${this.themeId}`)
            })
            products.length &&
              daEventCenter.triggerNotice({
                daId: '2-3-13',
                target: products,
                extraData: {
                  activity_from: this.analysis?.sa?.activity_from || '',
                  style: this.analysis?.sa?.style || '',
                  sets_list: sets_list.join(','),
                  abtest: this.analysis?.sa?.abtest || '',
                },
              })
          })
        },
      })
    },
    transformImg,
  },
  emits: ['handleItemClick', 'onSlideChangeEnd'],
}
</script>

<style lang="less">
.quick-view__left-swiper {
  &__prev, &__next{
      width: 36px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: fade(#fff, 94%);
      border-radius: 50%;
      z-index: 12;
      cursor: pointer;
      box-shadow: 0 2px 5px fade(#000, 8%);
      i{
          font-size: 16px;
      }
      .svgicon {
        width: 16px;
        height: 18px;
      }
      &.swiper-button-disabled{
          display: none;
      }
      &:hover{
          background: fade(#fff, 100%);
      }
  }
  &__prev{
        position: absolute;
        left: 10px;
        right: auto;
        top: 50%;
        margin-top: -18px;
    }
    &__next{
        position: absolute;
        right: 10px;
        left: auto;
        top: 50%;
        margin-top: -18px;
    }
    [mir=rtl] &__prev{
        right: 10px;
        left: auto;
    }
    [mir=rtl] &__next{
        left: 10px;
        right: auto;
    }
}
.theme-image-swiperv2__prev {
  background: none;
  box-shadow: none;
  left: -12px;
}
.theme-image-swiperv2__next {
  background: none;
  box-shadow: none;
}
.theme-image-swiper-wrapper .theme-image__left-main {
  .img-wrapper {
    width: calc(100% - 38px);
  }
}
</style>
