<template>
  <s-dialog
    v-model:visible="newImagesShow"
    class="productimg-extend-modal"
    :type="'full'"
    :show-close="true"
    :close-on-click-modal="false"
    :append-to-body="true"
    :immediately-render="true"
    @close="close()"
  >
    <div class="productimg-extend">
      <div class="productimg-extend__thumbnails">
        <ul class="productimg-extend__thumbnails-list">
          <li
            v-for="(item, index) in imagesArr"
            :key="index"
            class="productimg-extend__thumbnails-item"
            :class="{
              'productimg-extend__thumbnails-item_active':
                activeIndex === index,
            }"
            @click="handleThumbItem(index, item)"
            @mouseenter="handleThumbItem(index, item)"
          >
            <template v-if="item.isVideo">
              <div
                class="product-intro__play"
                @mouseenter="isShowContol = true"
              >
                <i class="svgicon svgicon-play1"></i>
                <div>{{ language.SHEIN_KEY_PC_17189 }}</div>
              </div>
            </template>
            <template v-else>
              <img
                :src="transformImg({ img: item.thumbnail })"
                :alt="goodsName"
              />
            </template>
          </li>
        </ul>
      </div>
      <div
        ref="container"
        class="productimg-extend__image-container j-productimg-extend__image-container"
      >
        <!-- vimeo组件 -->
        <VimeoPlayer
          v-if="videoUrl"
          ref="VimeoPlayer"
          :show="isShow"
          :url="videoUrl"
          @is-cilck-full-screen="isCilckFullScreen"
          @fail-loaded="failLoaded"
        />
        <div
          v-show="!isShow"
          class="productimg-extend__main-image"
        >
          <img
            class="big-img-hook"
            :class="{ 'cursor-zoom-in': !isOriginal}"
            :src="transformImg({ img: activeImg })"
            :alt="goodsName"
            @mouseenter="calcIsOriginal()"
            @click.stop="enlargeImage($event)"
          />
        </div>

        <div
          v-if="!isShow && originImageShow"
          class="productimg-extend__original-image"
        >
          <img
            class="cursor-zoom-out"
            :src="transformImg({ img: activeImg })"
            :style="{
              transform:
                'translate3d(' +
                -offset.x +
                'px, ' +
                -offset.y +
                'px, ' +
                '0px)',
              width: oriImg.width + 'px',
              height: oriImg.height + 'px',
            }"
            :alt="goodsName"
            @mousemove="moveImage($event)"
            @mousedown="mouseDown($event)"
            @mouseup="mouseUp"
          />
        </div>
      </div>
    </div>
  </s-dialog>
</template>

<script>
import 'public/src/icon/play1.svg'
import { transformImg } from '@shein/common-function'
import { mapState } from 'vuex'
import { defineAsyncComponent } from 'vue'
let { GB_cssRight } = gbCommonInfo

export default {
  name: 'QuickViewImageModal',
  components: {
    VimeoPlayer: defineAsyncComponent(() => import('public/src/pages/goods_detail_v2/components/VimeoPlayer.vue')),
  },
  props: {
    imagesShow: {
      type: Boolean,
      default: false,
    },
    imagesArr: {
      type: Array,
      default() {
        return []
      },
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    videoUrl: {
      type: String,
      default: '',
    },
    magnification: {
      // 无原图直接放大时的比率
      type: Number,
      default: 1,
    },
    goodsName: {
      type: String,
      default: '',
    },
    atomFlowConfig: {
      type: Object,
      default: () => ({
        imgShow: false,
        analysis: {}
      })
    }
  },
  data() {
    return {
      GB_cssRight,
      originImageShow: false,
      originImageSrc: '',
      oriImg: {
        width: 1340,
        height: 1785,
      },
      container: {
        width: 0,
        height: 0,
      },
      offset: {
        x: 0,
        y: 0,
      },
      maxOffset: {
        x: 0,
        y: 0,
      },
      ox: 0,
      oy: 0,
      ratiox: 0,
      ratioy: 0,
      mouseDownTime: 0,
      isOriginal: false,
      isShow: false,
      isLoaded: false,
      setFullScreen: false,
      newImagesShow: false,
      isCalFlowLeft: false,
      flowOffset: '29%'
    }
  },
  computed: {
    ...mapState(['language']),
    activeImg() {
      return this.imagesArr[this.activeIndex]?.origin_image || ''
    },
    getRenderFlowStyle() {
      const { flowOffset } = this
      let type = GB_cssRight ? 'right' : 'left'
      console.log(GB_cssRight)
      return {
        [type]: flowOffset
      }
    }
  },
  watch: {
    activeIndex() {
      this.isShow = this.imagesArr[this.activeIndex].isVideo ? true : false
    },
    imagesShow() {
      this.newImagesShow = !!this.imagesShow
      this.newImagesShow && this.calFlowLeft()
    },
  },
  methods: {
    transformImg: transformImg,
    close() {
      this.$emit('close')
      setTimeout(() => {
        this.originImageShow = false
        if (this.isPlay) {
          this.vimeoPlayer
            .pause()
            .then(() => {
              this.isPlay = false
            })
            .catch((err) => {
              console.log(err, 'pause error')
            })
        }
      }, 300)
    },
    /**
     * 绑定缩略图点击事件
     */
    handleThumbItem(index, item) {
      this.$emit('change-index', { index })
      this.originImageSrc = this.imagesArr[index].origin_image
      this.lessenImage()
      this.isShow = item.isVideo ? true : false
      this.$nextTick(() => {
        if (!this.videoUrl && !this.isLoaded) return
        item.isVideo && this.$refs.VimeoPlayer.playVideo()
        !item.isVideo && this.$refs.VimeoPlayer.pauseVideo()
        if (!this.isShow) return
        //点击视频
        daEventCenter.triggerNotice({
          daId: '1-8-6-39',
        })
        //视频曝光
        daEventCenter.triggerNotice({
          daId: '1-8-6-38',
        })
        // }
      })
    },
    /**
     * 判断当前展示图片是否为原图
     */
    calcIsOriginal() {
      this.getImgNaturalSize()
      this.getContainerSize()
      if (
        this.container.width >= this.oriImg.width &&
        this.container.height >= this.oriImg.height
      ) {
        this.isOriginal = true
      } else {
        this.isOriginal = false
      }
    },
    /**
     * 点击查看原图
     */
    enlargeImage(e) {
      if (this.isOriginal && this.magnification == 1) {
        return
      }
      let dom = typeof e?.target === 'string' ? document.querySelector(e.target) : e.target
      if (!dom) return
      let domRect = dom.getBoundingClientRect()
      let domWidth = domRect.width
      let domHeight = domRect.height
      // 点击时鼠标在图片的位置X,Y(百分比)
      let pointX = (e.clientX - domRect.left) / domWidth
      let pointY = (e.clientY - 25) / domHeight


      this.ox = e.clientX
      this.oy = e.clientY

      //console.log(domWidth, domHeight, "****", dom.offset().left, 25, "****", e.clientX, e.clientY, "****", pointX, pointY);
      this.setOffset(pointX, pointY, true)

      this.originImageShow = true
    },
    /**
     *获取原图尺寸
     */
    getImgNaturalSize() {
      let img = new Image()
      img.src = this.transformImg({
        img: this.imagesArr[this.activeIndex].origin_image,
      })
      this.oriImg.width = img.width * this.magnification
      this.oriImg.height = img.height * this.magnification
      //console.log(this.oriImg.width, this.oriImg.height, "&&&&&&&&");
    },
    /**
     *获取图片展示区域宽高,图片最大的偏移量
     */
    getContainerSize() {
      let dom = this.$refs.container
      if (!dom) return
      // 图片展示区域宽高
      this.container.width = dom.clientWidth
      this.container.height = dom.clientHeight

      //X, Y方向最大偏移量
      //容器对图片宽高的百分比, 鼠标移动是计算偏移量需要
      let diffX = this.container.width - this.oriImg.width,
          diffY = this.container.height - this.oriImg.height
      if (diffX < 0) {
        this.maxOffset.x = Math.abs(diffX)
        this.ratiox = Math.ceil(this.oriImg.width / this.container.width)
      } else {
        this.maxOffset.x = 0
        this.ratiox = 0
      }
      if (diffY < 0) {
        this.maxOffset.y = Math.abs(diffY)
        this.ratioy = Math.ceil(this.oriImg.height / this.container.height)
      } else {
        this.maxOffset.y = 0
        this.ratioy = 0
      }

      //console.log(this.container.width, this.container.height, "#######");
    },
    /**
     *设置偏移量
     */
    setOffset(x, y, isEnlarge) {
      if (isEnlarge) {
        //_x, _y 点击小图时对应在大图的位置
        let _x = x * this.oriImg.width,
            _y = y * this.oriImg.height,
            offsetX = _x - this.container.width / 2,
            offsetY = _y - this.container.height / 2

        if (offsetX > this.maxOffset.x) {
          this.offset.x = this.maxOffset.x
        } else if (offsetX > 0) {
          this.offset.x = offsetX
        }

        if (offsetY > this.maxOffset.y) {
          this.offset.y = this.maxOffset.y
        } else if (offsetY > 0) {
          this.offset.y = offsetY
        }
      } else {
        //鼠标移动时图片对应偏移
        let _x = this.offset.x + x * this.ratiox,
            _y = this.offset.y + y * this.ratioy

        if (_x <= 0) {
          this.offset.x = 0
        } else if (_x > this.maxOffset.x) {
          this.offset.x = this.maxOffset.x
        } else {
          this.offset.x = _x
        }

        if (_y <= 0) {
          this.offset.y = 0
        } else if (_y > this.maxOffset.y) {
          this.offset.y = this.maxOffset.y
        } else {
          this.offset.y = _y
        }
      }
    },
    /**
     * 鼠标滑动移动图片
     */
    moveImage(e) {
      e.preventDefault()

      let x = e.clientX,
          y = e.clientY
      this.setOffset(x - this.ox, y - this.oy, false)

      this.ox = x
      this.oy = y
    },
    mouseDown(e) {
      this.mouseDownTime = new Date().getTime()
      this.moveImage(e)
    },
    mouseUp() {
      const nowTime = new Date().getTime()
      // 300 ms
      if (nowTime - this.mouseDownTime <= 300) {
        this.lessenImage()
        return
      }
    },
    /**
     * 点击关闭原图
     */
    lessenImage() {
      this.originImageShow = false
      this.offset.x = 0
      this.offset.y = 0
    },
    isCilckFullScreen(bol) {
      this.setFullScreen = bol
    },
    failLoaded(bol) {
      this.isLoaded = !bol
    },
    async calFlowLeft() {
      if (this.isCalFlowLeft) return
      await this.$nextTick()
      const target = document.getElementsByClassName('big-img-hook')?.[0]
      const { left = 0, right = 0 } = target?.getBoundingClientRect()
      if (!left)  {
        this.flowOffset = '29%' // 兜底
      } else {
        const THUMB_WIDTH = 82
        const PADDING = 8
        const POSITION = GB_cssRight ? window.innerWidth - right : left
        const OFFSET = GB_cssRight ? THUMB_WIDTH + PADDING : THUMB_WIDTH - PADDING
        this.flowOffset = `${POSITION - OFFSET}px`
      }
      this.isCalFlowLeft = true
    }
  },
  emits: ['change-index', 'close'],
}
</script>

<style lang="less">
.productimg-extend {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px 0;
  .padding-l(85px);
  .padding-r(55px);
  &__thumbnails {
    width: 60px;
    position: absolute;
    .left(25px);
    top: 0;
    bottom: 0;
    padding: 25px 0;
    .padding-r(3px);
  }
  &__thumbnails-list {
    max-height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #fff;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(224, 224, 224, 1);
      }
    }
  }
  &__thumbnails-item {
    position: relative;
    width: 50px;
    height: 66px;
    overflow: hidden;
    cursor: pointer;
    background-color: #f7f6f7;
    /* rw:begin */
    opacity: 0.5;
    background-color: #f5f5f5;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
    .product-intro__play {
      .svgicon {
        /* rw:begin */
        font-size: 16px;
        border-radius: 15px;
        top: 10px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-indent: 2px;
      }
    }
    &_active {
      &::before {
        /* sh:begin */
        position: absolute;
        .left(0);
        .right(0);
        top: 0;
        bottom: 0;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid #222;
        box-shadow: 0 0 0 2px #fff inset;
        background: transparent;
      }
      /* rw:begin */
      opacity: 1;
    }
    & + .productimg-extend__thumbnails-item {
      margin-top: 10px;
    }
  }
  &__image-container {
    position: relative;
    height: 100%;
    direction: ltr;
  }
  &__main-image {
    position: relative;
    height: 100%;
    overflow: hidden;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      max-height: 100%;
      max-height: 100%;
    }
  }
  &__original-image {
    position: absolute;
    .left(0);
    .right(0);
    top: 0;
    bottom: 0;
    background: #fff;
    overflow: hidden;
    img {
      position: relative;
      margin: 0 auto;
      display: block;
    }
  }
  // &__flow {
  //   left: 29% !important;
  // }
}
</style>
