<template>
  <div
    v-if="propData"
    class="quick-view__footer"
  >
    <div
      v-if="propData.collectImg"
      class="quick-view__footer-img"
    >
      <img
        class=""
        :src="transformImg({ img: propData.collectImg })"
        @click="handleMainImgClick"
      />
    </div>
    <Icon
      v-if="activeIndex > 0"
      ref="swiper-prev-btn"
      name="sui_icon_more_left_12px"
      class="quick-view__footer-prev"
      :is-rotate="GB_cssRight"
      @click="handleArrowBtn('left')"
    />

    <!-- swiper -->
    <div
      v-if="footerProduct.length"
      ref="quick-view__footer-swiper"
      class="quick-view__footer-swiper"
    >
      <swiper-container
        ref="swiper"
        init="false"
        class="s-swiper-wrapper j-da-event-box"
        :code="analysis && analysis.sa && analysis.sa.code"
        :data-title="analysis && analysis.ga && analysis.ga.moduleTitle"
        :data-active-from="analysis && analysis.sa && analysis.sa.activity_from"
        data-style="popup"
        data-module="module"
      >
        <swiper-slide
          v-for="(item, index) in footerProduct"
          ref="quick-view__footer-item"
          :key="index"
          class="s-swiper-slide quick-view__footer-slider"
          :class="{ active: item.goods_id == activeId }"
          :data-goods_id="item.goods_id"
          :data-spu="item.productRelationID || item.goods_sn"
          :data-us-price="item.salePrice && item.salePrice.usdAmount"
          :data-us-origin-price="item.retailPrice && item.retailPrice.usdAmount"
          :data-goods-id="item.goods_id"
          :data-cat_id="item.cat_id"
          :data-sku="item.goods_sn"
          :data-index="index"
          :data-price="item.salePrice && item.salePrice.amount"
          :data-mall_tag_code="`${getMallTag(item.mall_code)}_${
            item.mall_code || ''
          }`"
          :data-theme-name="propData.theme_name || ''"
          :data-theme-id="propData.id || ''"
        >
          <!-- @click="handleItemClick" -->

          <div class="inner">
            <img
              class="lazyload item-img"
              :src="LAZY_IMG"
              :data-src="
                transformImg({ img: item.goods_thumb || item.goods_img })
              "
            />
          </div>

          <p :class="{ sale: discountCalc(item) }">
            <template
              v-if="
                quickViewConfig.showNewFlashPriceOnSale &&
                  item.newFlashPromotion
              "
            >
              {{
                item.newFlashPromotion.price &&
                  item.newFlashPromotion.price.amountWithSymbol
              }}
            </template>
            <template v-else>
              {{ item.salePrice && item.salePrice.amountWithSymbol }}
            </template>
          </p>

          <del v-if="discountCalc(item) && item.retailPrice">{{
            item.retailPrice.amountWithSymbol
          }}</del>

          <template v-if="quickViewConfig.showSheinClubDiscountValue">
            <ProductItemSheinClubDiscount
              class="club"
              type="small"
              :discount-value="calClubDisCountValue(item)"
            />
          </template>
        </swiper-slide>
      </swiper-container>
    </div>
    <!-- 向下按钮 -->
    <Icon
      v-if="!footerSwiper?.isEnd"
      ref="swiper-next-btn"
      name="sui_icon_more_right_12px"
      class="quick-view__footer-next"
      :is-rotate="GB_cssRight"
      @click="handleArrowBtn('right')"
    />
  </div>
</template>

<script>
// global variable:, LAZY_IMG, GB_SHEIN_lazyLoadInstance
import { transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
import { Icon } from '@shein-aidc/icon-vue3'
import ProductItemSheinClubDiscount from 'public/src/pages/components/productItem/components/ProductItemSheinClubDiscount.vue'
daEventCenter.addSubscriber({ modulecode: '2-3' })
import { register } from 'swiper/element'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()
const { GB_cssRight } = gbCommonInfo

export default {
  components: { ProductItemSheinClubDiscount, Icon },
  props: {
    propData: {
      type: Object,
      default() {
        return {}
      },
    },
    activeId: {
      type: [String, Number],
      default() {
        return ''
      },
    },
    analysis: {
      type: Object,
      default() {
        return {}
      },
    },
    quickViewCb: {
      type: Object,
      default() {
        return {}
      },
    },
    rootStatus: {
      type: Object,
      default() {
        return {}
      },
    },
    siteMallInfo: {
      type: Array,
      default() {
        return []
      },
    },
    quickViewConfig: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  inject: ['changeRootStatus', 'changeRootData'],
  data() {
    return {
      GB_cssRight,
      LAZY_IMG,
      footerSwiper: null,
      shouldInit: false,
      button: {
        init: false,
        show: false,
        prev: false,
        next: false,
      },
      activeIndex: 0
    }
  },
  computed: {
    footerProduct() {
      return (this.propData && this.propData.product) || []
    },
    sliderToIndex() {
      return this.footerProduct.findIndex((i) => i.goods_id == this.activeId) || 0
    },
  },
  watch: {
    footerProduct: {
      deep: true,

      handler(data) {
        if (!data.length) return
        this.$nextTick(() => {
          document.querySelectorAll('.quick-view__footer-swiper .lazyload')?.forEach?.(element => {
            element.removeAttribute('loaded')
            element.setAttribute('src', this.LAZY_IMG)
          })

          if (typeof GB_SHEIN_lazyLoadInstance != 'undefined') {
            GB_SHEIN_lazyLoadInstance.update()
          }
          if (this.rootStatus.popupModal) {
            this.initSwiper()
          } else {
            this.shouldInit = true
          }
        })
      },

      immediate: true,
    },
    'rootStatus.popupModal'(newVal) {
      if (newVal) {
        if (this.shouldInit) {
          this.shouldInit = false
          this.initSwiper()
        }
      }
    },
  },
  methods: {
    /**
     * 点击左右箭头
    */
    handleArrowBtn(direction) {
      if (direction === 'left') {
        this.footerSwiper.slidePrev()
      } else if (direction === 'right') {
        this.footerSwiper.slideNext()
      }
    },
    getMallTag(mall_code) {
      return (
        this.siteMallInfo.find((_) => _.mall_code == mall_code)?.mall_tags || ''
      )
    },
    /**
     * 点击主图
     */
    handleMainImgClick() {
      this.changeRootStatus({
        isShowFooterMainImg: true,
      })
      this.changeRootData({
        currentGoodsId: '',
      })
    },
    /**
     * 初始化Swiper
     */
    initSwiper() {
      this.$nextTick(() => {
        this.activeIndex = 0
        const swiperEl = this.$refs.swiper
        if (!swiperEl) return

        // if (!this.button.init) {
        //   this.button.init = true
        //   this.$nextTick(() => {
        //     if (
        //       this.footerProduct.length * 76 +
        //         (this.footerProduct.length - 1) * 12 >
        //       this.$refs['quick-view__footer-swiper'].offsetWidth
        //     ) {
        //       this.button.show = true
        //     }
        //     this.initSwiper()
        //   })
        //   return
        // }
        this.swiperItemsExpose(this.sliderToIndex)
        // if (this.footerSwiper) {
        // this.footerSwiper.init()
        
        // this.button.next =
        //   this.footerSwiper.snapIndex != this.footerSwiper.snapGrid.length - 1
        // this.button.prev = this.footerSwiper.snapIndex != 0
        // if (this.footerProduct.length < 4) {
        //   this.$nextTick(() => {

        //   })
        // } else {
        //   this.footerSwiper.params.loopedSlides = this.footerProduct.length
        //   this.footerSwiper.reLoop()
        //   this.footerSwiper.init()
        // }

        // return
        // }

        const swiperParams = {
          observer: true,
          observeParents: true,
          slidesPerView: 'auto',
          slidesPerGroup: 5,
          roundLengths: true,
          speed: 300,
          on: {
            slideChangeTransitionEnd: (swiper) => {
              this.activeIndex = swiper.realIndex
              this.$nextTick(() => {
                this.swiperItemsExpose(swiper.realIndex)
              })
            },
          }
        }
        Object.assign(swiperEl, swiperParams)
        swiperEl.initialize()
        this.footerSwiper = swiperEl.swiper
        this.footerSwiper.slideTo(this.sliderToIndex, 0, false)
        this.activeIndex = this.sliderToIndex 
        this.bindEvent()
      })
    },
    // slideHack(type) {
    //   // swiper slidesPerView:auto时，最后一次滑动不触发回调函数
    //   clearTimeout(time)
    //   this.clickButton = true
    //   time = setTimeout(() => {
    //     if (this.clickButton) {
    //       this.button.next = type != 'next'
    //       this.button.prev = type == 'next'
    //     }
    //   }, 350)
    // },
    /**
     * swiper item 的曝光
     */
    swiperItemsExpose(swiperIndex) {
      const expDoms = []

      const allDoms = this.$refs['quick-view__footer-item']
      const doms = [...allDoms].slice(swiperIndex, swiperIndex + 5)

      // 1. 执行配置的回调函数
      const { handleFooterSwiperSlideEnd } = this.quickViewCb
      if (typeof handleFooterSwiperSlideEnd == 'function') {
        const bol = handleFooterSwiperSlideEnd(doms)
        // 是否触发后续的埋点由调用方决定
        if (bol) return
      }

      // 2. 触发曝光埋点
      doms.forEach((dom) => {
        const viewed = dom.getAttribute('viewed')
        if (viewed == this.activeId) return
        // const goodsId = dom.getAttribute('data-goods_id')
        dom.setAttribute('viewed', this.activeId)
        expDoms.push(dom)
      })
      // 商品曝光
      expDoms.length &&
        daEventCenter.triggerNotice({
          daId: '2-3-2',
          target: expDoms,
        })
    },
    bindEvent() {
      // swiper会动态生成商品进行滚动，所以手动绑定事件
      this.$refs['quick-view__footer-swiper']?.addEventListener?.('click', (event) => {
        const slide = event.target.closest('.s-swiper-slide')
        const goods_id = slide.getAttribute('data-goods_id')
        const index = slide.getAttribute('data-index')

        if (goods_id) {
          if (this.activeId == goods_id) return
          this.$emit('handleItemClick', { goods_id })

          // 给对应的item加上选中的边框
          this.$nextTick(() => {
            const swiperSlider = this.$refs['quick-view__footer-swiper']?.querySelectorAll('.s-swiper-slide')
            swiperSlider.forEach(slide => {
              const goodsId = slide.dataset['goods_id']
              if (goods_id == goodsId) {
                slide.classList.add('active')
              } else {
                slide.classList.remove('active')
              }
            })
          })

          // 点击事件回调扩展 S
          // 1. 执行配置的回调函数
          const { handleFooterSwiperItemClick } = this.quickViewCb
          if (typeof handleFooterSwiperItemClick == 'function') {
            const bol = handleFooterSwiperItemClick(goods_id, index)
            // 是否触发后续的埋点由调用方决定
            if (bol) return
          }
          // 2. 触发配置埋点
          daEventCenter.triggerNotice({
            daId: '2-3-1',
            target: slide,
          })
          // 点击事件回调扩展 E
        }
      })
    },
    discountCalc(item) {
      return (
        item.unit_discount > 0 ||
        (item.salePrice &&
          item.retailPrice &&
          item.salePrice.amount > item.retailPrice.amount) ||
        item.newFlashPromotion
      )
    },
    calClubDisCountValue(item) {
      return item?.sheinClubPromotionInfo?.discountValue || 0
    },
    transformImg,
  },
  emits: ['handleItemClick'],
}
</script>
