<script>
/**
 * 一键购商品详情校验组件
 * 1. 校验当前用户是否有可用一键购订单
 * 2. 校验通过后将订单信息冒泡给父级
 */
import schttp from 'public/src/services/schttp'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'

export default {
  name: 'OneClickPay',
  props: {
    // 是否启用鉴权
    shouldAuth: {
      type: Boolean,
      default: false,
    },
    // 商品详情
    goodsData: {
      type: Object,
      default: () => ({})
    },
    // 当前mallCode
    currentMallCode: {
      type: [String, Number],
      default: '',
    },
    // 当前订单号
    billNo: {
      type: [String, Number],
      default: '',
    },
    // 从外部触发重新校验的入口
    stage: {
      type: Number,
      default: 1
    },
  },
  emits: ['verifyDone'],
  data() {
    return {
      // 已鉴权
      verified: false,
      // 是否有一键购权限
      hasQualification: false,
    }
  },
  computed: {
  },
  watch: {
    shouldAuth(should) {
      const { verified, verifyUtil } = this
      if (should && !verified) {
        verifyUtil()
      }
    },
    'goodsData.goods_id'() {
      this.verified = false
      this.hasQualification = false
      const { shouldAuth, verifyUtil } = this
      if (shouldAuth) {
        verifyUtil()
      }
    },
    currentMallCode() {
      this.verified = false
      this.hasQualification = false
      const { shouldAuth, verifyUtil } = this
      if (shouldAuth) {
        verifyUtil()
      }
    },
    // 从外部触发更新时，等待数据拉取后才更新当前状态
    stage() {
      const { shouldAuth, verifyUtil } = this
      if (shouldAuth) {
        verifyUtil()
      }
    },
  },
  created() {
    const { shouldAuth, verifyUtil } = this
    if (shouldAuth) {
      verifyUtil()
    }
  },
  methods: {
    async verifyUtil() {
      this.verified = true
      const { verify } = this
      const verifyResult = await verify()
      this.hasQualification = !!verifyResult
      this.$emit('verifyDone', {
        hasQualification: !!verifyResult,
        billInfo: verifyResult
      })
    },
    async verify() {
      const { goodsData, billNo, currentMallCode } = this
      const { goods_id, goods_sn } = goodsData
      if (!goods_id || !goods_sn) {
        return null
      }

      const isLogin = isLoginFn()
      if (!isLogin) {
        return null
      }

      const requestData = {
        goodsId: goods_id,
        goodsSn: goods_sn,
      }
      if (billNo) {
        requestData.billno = billNo
      }
      const mallCodeString = String(currentMallCode)
      try {
        const res = await schttp({
          url: '/api/productInfo/oneClickPayQualification/get',
          method: 'POST',
          data: requestData,
        })
        const { code, info } = res || {}
        const { billno, mall_code } = info || {}
        const resultMallCode = String(mall_code)
        if (code === '0' && billno && resultMallCode === mallCodeString) {
          return info
        }
      } catch (err) {
        //
      }
      return null
    },
  },
}
</script>
