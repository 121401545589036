<template>
  <div 
    v-if="source.length>0"
    :class="{ 'quick-atmosphere-flow': true, 'transparent': !show }"
  >
    <div
      v-if="!firstIsPaused"
      :style="{'animation-play-state': firstIsPaused ? 'paused' : 'running'}"
      class="tag-item"
      :class="{ 'tag-item_white': showWhiteStyle}"
      @animationiteration="firstPlay"
    >
      <span>{{ source[currentIndex]?.labelLang }} </span>
    </div>
    <div
      v-if="!secondIsPaused"
      :style="{'animation-play-state': secondIsPaused ? 'paused' : 'running'}"
      class="tag-item"
      :class="{ 'tag-item_white': showWhiteStyle}"
      @animationiteration="secondPlay"
    >
      <span>{{ source[currentNextIndex]?.labelLang }}</span>
    </div>
  </div>
</template>

<script setup>
/**
 * @component 快速加车人气氛围信息滚动展示框
 */

// 已上报埋点的标签tagId列表
const REPORTED_LABELS = new Set()

import {
  ref,
  defineProps,
  toRefs,
  computed,
  onMounted,
  onBeforeUnmount,
  watch
} from 'vue'
import schttp from 'public/src/services/schttp'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-6-1' })
const props = defineProps({
  // 是否展示，只控制透明度，不影响数据和dom占位
  show: {
    type: Boolean,
    default: true
  },
  // 是否自动播放
  autoPlay: {
    type: Boolean,
    default: true
  },
  productInfo: {
    type: Object,
    default: () => ({})
  },
  // 埋点数据
  analysisInfo: {
    type: Object,
    default: () => ({})
  },
  config: {
    type: Object,
    default: () => ({})
  },
  location: {
    type: String,
    default: ''
  }
})
const { show, autoPlay, config, location, productInfo } = toRefs(props)

/**
 * @vuex store
 */
const goodsId = computed(() => productInfo.value.goods_id)
const showWhiteStyle = computed(() => config.value?.toastcolor === 'white')

const source = ref([])
const displayInfoLabel = ref([])
// 数据请求计时器
const playTimer = ref(null)
const playNextTimer = ref(null)
// 当前tag指数
const currentIndex = ref(0)
const currentNextIndex = ref(1)
const firstIsPaused = ref(true)
const secondIsPaused = ref(true)

const maxLength = computed(() => {
  return source.value.length || 0
})

async function getDisplayInfo() {
  if(displayInfoLabel.value.length) return displayInfoLabel.value
  const { goods_id, goods_sn, cat_id, store_code, stock } = productInfo.value || {}
  let labels = []
  const { code, displayInfo } = await schttp({
    url: `/api/productInfo/displayWindowInfo/query`,
    method: 'POST',
    data: { 
      goods_id, 
      goods_sn, 
      cat_id, 
      sceneType: 'fast_add_cart', 
      showBehaviorLabel: 1, 
      store_code, 
      stock
    }
  })
  if (code == 0) {
    const { userBehaviorLabels } = displayInfo?.detailAndQuickCommonView || {}
    labels = userBehaviorLabels || []
    if(displayInfoLabel.value.length === 0) {
      displayInfoLabel.value = userBehaviorLabels
    }
  }
  return labels
}

// 拉取氛围信息
async function refreshAtmosphereData() {
  try {
    const labels = await getDisplayInfo()
    if (Array.isArray(labels) && labels.length) {
      source.value = labels
      // 开始播放
      if (autoPlay.value) {
        clearTimeout(playTimer.value)
        play()
        return
      }
    }
  } catch (err) {
    //
  }
  // 默认不展示
  source.value = []
}

// 播放动画
function play() {
  currentIndex.value = 0
  playExpose(0)
  currentNextIndex.value = 1
  playExpose(1)
  firstIsPaused.value = false
  if(maxLength.value > 1){
    playTimer.value = setTimeout(() => {
      secondIsPaused.value = false
    }, 2500)
  } 
}

function firstPlay(){
  if(currentIndex.value < maxLength.value - 2){
    currentIndex.value = currentIndex.value + 2
    playExpose(currentIndex.value)
  }else{
    firstIsPaused.value = true
    if(currentIndex.value === maxLength.value - 1){
      playNextTimer.value = setTimeout(() => {
        play()
      }, 3000)
    } 
  }
}
function secondPlay(){
  if(currentNextIndex.value + 2 < maxLength.value){
    currentNextIndex.value = currentNextIndex.value + 2
    playExpose(currentNextIndex.value)
  }else{
    secondIsPaused.value = true
    if(currentNextIndex.value === maxLength.value - 1){
      playNextTimer.value = setTimeout(() => {
        play()
      }, 3000)
    } 
  }
}

function playExpose(index){
  // 真曝光，不显示不曝光
  const tag = source.value[index]
  const { labelId, label_type } = tag || {}
  if (!tag || !labelId || REPORTED_LABELS.has(labelId)) {
    return
  }
  daEventCenter.triggerNotice({
    daId: '1-6-1-139',
    extraData: {
      ...(props.analysisInfo || {}),
      location: location.value,
      label_id: labelId,
      label_type
    }
  })
  REPORTED_LABELS.add(labelId)
}

// 切换颜色等操作时更新标签
watch(goodsId, () => {
  displayInfoLabel.value = []
  refreshAtmosphereData()
})


onMounted(() => {
  refreshAtmosphereData()
})
onBeforeUnmount(() => {
  clearTimeout(playTimer.value)
  clearTimeout(playNextTimer.value)
  firstIsPaused.value = true
  secondIsPaused.value = true
})
</script>

<style lang="less" scoped>
.quick-atmosphere-flow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  opacity: 1;
  transition: opacity 0.1s;
  z-index: @zindex-swiper-arrow;

  &.transparent {
    opacity: 0;
  }

  .tag-item {
    display: inline-block;
    // height: 22px;
    max-width: calc(67% - 20px);
    border-radius: 27px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 6px 8px;
    line-height: 1;
    font-size: 0;
    position: absolute;
    left: 10px;
    top: 85px;
    opacity: 0;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    animation-name: scroll;
    animation-duration: 5.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    span {
      display: inline-block;
      max-width: 100%;
      font-size: 12px;
      line-height: 1;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_white {
      background-color: rgba(255, 255, 255, 0.94);

      span {
        color: #000;
      }
    }
  }
}

@keyframes scroll{
    0%{
        opacity: 0;
        transform: translateY(-46px)
    }
    9%{
        opacity: 1;
        transform: translateY(-70px)
    }
    45%{
        opacity: 1;
        transform: translateY(-70px);
    }
    54%{
        opacity: 0;
        transform: translateY(-70px)
    }
    100%{
        opacity: 0;
        transform: translateY(-70px)
    }
}
</style>
