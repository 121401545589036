<template>
  <div
    v-if="!!promotionText"
    class="product-color__promotion-link"
  >
    <div
      v-expose="promotionEntryExpose()"
      v-tap="promotionEntryClick()"
      class="product-color__promotion-link-box"
      :class="{ 'product-color__promotion-link-box_large' : from === 'large'}"
      @click="promotionLink"
    >
      <Icon
        class="product-color__promotion-link-icon"
        name="sui_icon_activity_12px"
        size="12px"
      />
      <div
        class="product-color__promotion-link-text"
      >
        {{ promotionText }}
      </div>
      <div class="product-color__promotion-link-right">
        >
      </div>
    </div>
  </div>
</template>
  
<script>
import { PromotionUtils } from 'public/src/pages/common/promotion/utils'
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'ColorDesc',
  components: {
    Icon
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    from: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    promotionText() {
      if(!(this.data && this.data.tagType === 2)) return ''
      return this.data?.tips?.text
    },
  },
  methods: {
    promotionLink() {
      this.data && PromotionUtils.jumpToAdd(this.data, 'goodsDetail')
    },
    promotionEntryExpose() {
      const { id, typeId } = this.data || {}
      return {
        id: '1-6-1-142',
        once: true,
        data: {
          src_module: 'promotion',
          src_identifier: `on=${typeId}\`cn=${id}\`ps=${this.currentIndex + 1}_1\`jc=promotion_${id}`,
        }
      }
    },
    promotionEntryClick() {
      const { id, typeId } = this.data || {}
      return {
        id: '1-6-1-143',
        data: {
          src_module: 'promotion',
          src_identifier: `on=${typeId}\`cn=${id}\`ps=${this.currentIndex + 1}_1\`jc=promotion_${id}`,
        }
      }
    },
  }
}
</script>
  
<style lang="less" scoped>
.product-color {
    &__promotion-link {
        margin: -0.11rem 0 0.64rem;
        display: flex;
    }
    &__promotion-link-box {
        width: 0;
        flex-grow: 1;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 14px;
        color: #C44A01;
        overflow: hidden;

        &_large {
           width: 380px;
           flex-grow: 0;
        }
    }
    &__promotion-link-text {
        margin: 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
  
