<template>
  <ProductIntroSize
    v-model:current-unit="currentUnit"
    :product-intro-data="goodsData"
    :language="language"
    :is-hidden-plus-size="quickViewConfig.isHiddenPlusSize"
    :check-not-selected-tips="rootStatus.notSelectSize"
    :external-size-info-index="addBagForm.selectedAttrIndex"
    :external-sku-code="addBagForm.selectedAttrSkuCode"
    :new-open="rootStatus.isFromNewOpen"
    :country="localCountry"
    :sku-no-stock="skuNoStock"
    :size-guide-in-title="true"
    :hide-mall-site="hideMallSite"
    :mall-code="goodsData.mallCode"
    :is-new-size-local="isNewSizeLocal"
    :ccc-config="cccConfig"
    :is-show-size-tips="isShowSizeTips"
    :site-uid="SiteUID"
    :show-quick-ship="isAbtShowQuickShip"
    :lang-path="langPath"
    :is-rw="IS_RW"
    :css-right="GB_cssRight"
    :config="config"
    :event-click-id="eventClickId"
    :find-similar-float-info="findSimilarFloatInfo"
    :show-attr-img-popover="showAttrImgPopover"
    :tsp-show-buy-size-table="tspShowBuySizeTable"
    from="quickAdd"
    @handle-change-local-size="handleChangeLocalSize"
    @handle-data="handleData"
    @compose="handleCompose"
    @open-size-guide="handleOpenSizeGuide"
    @mall-click="mallClick"
    @update-quick-ship="handleUpdateQuickShip"
    @handle-click-size-group-item="handleClickSizeGroupItem"
  />
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter'
import ProductIntroSize from '../../../goods_detail_v2/components/ProductIntroSize_v3.vue'

const { SiteUID, IS_RW, GB_cssRight, langPath } = gbCommonInfo

export default {
  name: 'GoodsSize',
  components: {
    ProductIntroSize,
  },
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    tspShowBuySizeTable: {
      type: Boolean,
      default: false
    },
    goodsData: {
      type: Object,
      default: () => ({}),
    },
    extendQvData: {
      type: Object,
      default: () => ({}),
    },
    quickViewConfig: {
      type: Object,
      default: () => ({}),
    },
    currentSizeUnit: {
      type: Number,
      default: 0,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    rootStatus: {
      type: Object,
      default: () => ({ notSelectSize: false }),
    },
    // 加车form
    addBagForm: {
      type: Object,
      required: true,
      default: () => ({ sizeInfo: null }),
    },
    // 当前国家
    localCountry: {
      type: String,
      default: '',
    },
    skuNoStock: {
      type: Object,
      default: () => ({}),
    },
    quickViewCb: {
      type: Object,
      default: () => ({}),
    },
    isNewSizeLocal: {
      type: Boolean,
      default: false
    },
    cccConfig: {
      type: Object,
      default: () => ({})
    },
    isShowSizeTips: {
      type: Object,
      default: () => ({})
    },
    isAbtShowQuickShip: {
      type: Boolean,
    },
    findSimilarFloatInfo: {
      type: Object,
      default: () => ({})
    },
    showAttrImgPopover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      IS_RW, 
      GB_cssRight,
      langPath,
      eventClickId: '1-8-6-5',
      currentUnit: 0,
      SiteUID
    }
  },
  computed: {
    mallCode() {
      return this.goodsData.mallCode
    },
    hideMallSite() {
      const { quickViewConfig = {} } = this
      return !!quickViewConfig.underPriceStyle
    },
  },
  watch: {
    currentSizeUnit: {
      deep: true,
      handler(val) {
        this.currentUnit = val
      },
      immediate: true,
    },
  },
  inject: ['changeRootData', 'changeRootStatus', 'changeAddBagData'],
  methods: {
    handleData({ saleAttrList }) {
      if (saleAttrList) this.changeRootData({ saleAttrList })
    },
    handleClickSizeGroupItem(item) {
      this.$emit('handleClickSizeGroupItem', item)
    },
    handleChangeLocalSize(value) {
      this.$emit('changeLocalCountry', value)
    },
    handleUpdateQuickShip (status) {
      this.goodsData.quickShip = status
    },
    mallClick(mallInfo) {
      this.$emit('mallClick', mallInfo)
      const { handleMallClick = undefined } = this.quickViewCb || {}
      if (typeof handleMallClick == 'function') {
        handleMallClick(mallInfo)
      }
    },
    handleCompose(params) {
      const { skuInfo = {}, curSelectAttr, attrs, mallStock } = params
      this.goodsData.mallStock = mallStock
      if (skuInfo && Object.keys(skuInfo).length) {
        const { salePrice } = this.extendQvData || {}
        const skuInfoBk = JSON.parse(JSON.stringify(skuInfo))
        // 外部扩展的价格展示
        if (salePrice) {
          skuInfoBk?.mall?.[this.mallCode]?.salePrice &&
            (skuInfoBk.mall[this.mallCode].salePrice = {
              ...skuInfoBk.mall[this.mallCode].salePrice,
              ...salePrice,
            })
        }
        this.changeRootStatus({
          notSelectSize: false,
        })
        this.changeAddBagData({
          sizeInfo: skuInfoBk,
          selectedAttrSkuCode: skuInfoBk.sku_code,
        })
      } else {
        this.changeAddBagData({
          sizeInfo: {},
          selectedAttrSkuCode: '',
          selectedAttrIndex: {},
        })
      }

      // 1. 执行配置的回调
      const sizeInfo = attrs.find((item) => item.attr_id == '87') || {}
      const { handleSizeClick = undefined } = this.quickViewCb || {}
      if (typeof handleSizeClick == 'function') {
        const bol = handleSizeClick({ sizeInfo, ...params })
        // 是否需要执行默认埋点上报由调用者决定
        if (bol) return
      }
      // 2. 执行默认的埋点上报
      if (curSelectAttr?.attr_id == '87') {
        const attrValueName = (attrs || []).find(
          (item) => item.attr_id == '87'
        )?.attr_value_name
        if (!attrValueName) return

        if(this.analysis?.daIdConfig?.selectSize) {
          daEventCenter.triggerNotice({
            daId: this.analysis?.daIdConfig?.selectSize,
            extraData: {
              sizeValue: attrValueName,
              eventCategory: this.analysis?.ga?.eventCategory,
            },
          })
        }
      }
    },
    handleOpenSizeGuide(open) {
      this.changeRootData({
        showSizeGuide: open,
      })
      this.changeRootStatus({
        popupModal: !open,
      })
    },
  },
  emits: ['mallClick', 'changeLocalCountry', 'handleClickSizeGroupItem'],
}
</script>
