<template>
  <ProductIntroColor
    v-if="!quickViewConfig.hideColorGroup"
    :product-intro-data="goodsData"
    :language="language"
    :new-open="rootStatus.isFromNewOpen"
    :load-conf="loadConf"
    :goods-desc="goodsDesc"
    :cdn-url="PUBLIC_CDN"
    :web-client="WEB_CLIENT"
    :colors-comp-data="colorsCompData"
    :fixed-ratio="fixedRatio"
    :show-attr-img-popover="showAttrImgPopover"
    :more-skc-price-infos-map="moreSkcPriceInfosMap"
    :close-popover="rootStatus.queryLoading"
    :attr-new-icon-ab="attrNewIconAb"
    @switch-color="handleSwitchColor"
  />
</template>

<script>
import ProductIntroColor from '../../../goods_detail_v2/components/Color_backup/index.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
const { PUBLIC_CDN, WEB_CLIENT } = gbCommonInfo

export default {
  name: 'GoodsColor',
  components: {
    ProductIntroColor,
  },
  props: {
    goodsData: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    rootStatus: {
      type: Object,
      default: () => ({
        notSelectSize: false,
      }),
    },
    quickViewCb: {
      type: Object,
      default: () => ({}),
    },
    analysis: {
      type: Object,
      default: () => ({}),
    },
    quickViewConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    moreSkcPriceInfosMap: {
      type: Object,
      default() {
        return {}
      },
    },
    colorsCompData: {
      type: Object,
      default() {
        return {}
      },
    },
    fixedRatio: {
      type: String,
      default: '3-4'
    },
    showAttrImgPopover: {
      type: Boolean,
      default: false,
    },
    attrNewIconAb: {
      type: String,
      default: () => ''
    },
    isColorDetailStrong: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      PUBLIC_CDN,
      WEB_CLIENT
    }
  },
  computed: {
    goodsDesc() {
      const goodsDesc = this.goodsData?.getBeautyGoodsDesc?.goodsDesc || ''
      return this.isColorDetailStrong && goodsDesc ? `<span style="color: #A86104;">${goodsDesc}</span>` : goodsDesc
    },
    loadConf() {
      return {
        showPromotionTagInfo: !!this.quickViewConfig?.showPromotionTagInfo,
        source: this.analysis?.source || '',
        num: 0,
        size: '',
        mode: 'quickView'
      }
    }
  },
  methods: {
    handleSwitchColor(item) {
      this.$emit('switchColor', item)

      // 1. 执行配置的回调
      const { handleColorClick = undefined } = this.quickViewCb || {}
      if (typeof handleColorClick == 'function') {
        const bol = handleColorClick(item)
        // 是否需要执行默认埋点上报由调用者决定
        if (bol) return
      }
      // 2. 执行默认的埋点上报
      const traceid = gbExposeTraceid('getComponent', {
        componentName: this.analysis.pageType || '',
      })
      daEventCenter.triggerNotice({
        daId: this.analysis?.daIdConfig?.selectColor || '2-8-7',
        extraData: {
          traceid,
          goods_id: item.goods_id,
          sku: item.goods_sn,
          eventCategory: this.analysis?.ga?.eventCategory || '',
        },
      })
    },
  },
  emits: ['switchColor'],
}
</script>
