<template>
  <div class="color-skeleton__container">
    <div
      v-for="i in colorsTotal"
      :key="`color-skeleton__item-${i}`"
      class="color-skeleton__item"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ColorSkeleton',
  props: {
    colorsTotal: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="less">
.color-skeleton {
  &__container {
    padding: 3px;
    display: inline-flex;
    flex-wrap: wrap;
  }
  &__item {
    margin: 0 18px 18px 0;
    padding: 3px;
    border: 3px solid transparent;
    border-radius: 50%;
    background-color: #e5e5e5;
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
}
</style>
