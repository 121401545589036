import { createApp } from 'vue'
import quickViewApp from './QuickView.vue'
import { registerBaseContext } from '../../common/common_inject'

let quickView = undefined

const getQuickView = () => {
  if (quickView) return quickView

  if (typeof window === 'undefined') return

  const tempDiv = document.createElement('div')
  tempDiv.classList.add('quick-view-outer-container')
  const instance = createApp(quickViewApp)
  registerBaseContext(instance)
  document.body.appendChild(tempDiv)
  const vm = instance.mount(tempDiv)
  window.QuickViewInstance = quickView = vm
  return vm
}

const QuickViewPopover = getQuickView()
export default QuickViewPopover
