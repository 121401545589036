<template>
  <div
    v-expose="{ id: '1-6-1-144', data: { type: 'banner' } }"
    class="best-deal-belt__container"
  >
    <div class="best-deal-belt__left">
      <Icon
        class="icon"
        name="sui_icon_best_deal_16px"
        size="16px"
      />
      {{ contentText }}
    </div>
    <div
      v-if="rightText"
      class="best-deal-belt__right"
    >
      {{ rightText }}
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'BestDealBelt',
  components: {
    Icon
  },
  props: {
    rightText: {
      type: String,
      default: ''
    },
    contentText: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => ({
        showRight: true
      })
    }
  }
}
</script>

<style lang="less">
.best-deal-belt {
  &__container {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 28px;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    opacity: 0.88;
    padding: 0 16px;
    color: @sui_color_white;
    background: @sui_color_promo;
    z-index: @zindex-hack;
    font-size: 12px;
  }
  &__left {
    flex: 1;
    height: 28px;
    line-height: 28px;
    vertical-align: bottom;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .icon {
      margin-right: 8px;
    }
  }
  &__right {
    margin-left: 12px;
    white-space: nowrap;
    font-weight: 400;
  }
}
</style>
